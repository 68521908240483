import { UPDATE_USER } from '../mutations';

const initialState = {
    currentUser: null,
    isAuthenticated: null
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER:
            return {
                ...state,
                currentUser: action.payload,
                isAuthenticated: action.payload !== null
            };
        default:
            return state;
    }
};
