import React, { } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavDropdown } from 'react-bootstrap';
import routes from "../../constants/routes";
import { withRouter } from "../../utils/withRouter";
import { logo } from "../../constants/images";
import { logOut } from "../../store/auth/actions";

const Header = (props) => {
    const { currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const onLogOut = () => {
        const { navigate } = props;
        dispatch(logOut());
        navigate(routes.login);
    }

    return (
        <nav className="navbar navbar-expand navbar-dark bg-dark osahan-nav-top p-0">
            <div className="container">
                <Link className="navbar-brand mr-2" to={routes.home}>
                    <img src={logo} alt="Logo" height="40" className="d-inline-block align-top" />
                </Link>
                <ul className="navbar-nav ml-auto d-flex align-items-center">
                    <li className="nav-item">
                        <Link className="nav-link" to={routes.jobs}><i className="feather-briefcase me-1"></i><span className="d-none d-lg-inline">Jobs</span></Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={routes.feeds}><i className="feather-briefcase me-1"></i><span className="d-none d-lg-inline">Feeds</span></Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={routes.rssFeeds}><i className="feather-briefcase me-1"></i><span className="d-none d-lg-inline">RSS Feeds</span></Link>
                    </li>
                    <NavDropdown title={`${currentUser.firstName} ${currentUser.lastName}`} className="nav-item no-arrow ml-1 osahan-profile-dropdown">
                        <NavDropdown.Item>
                            <Link to={routes.changePassword} className="text">
                                <i className="feather-user mr-1"></i> Change Password
                            </Link>
                        </NavDropdown.Item>
                        <div className="dropdown-divider"></div>
                        <NavDropdown.Item onClick={onLogOut}><i className="feather-log-out mr-1"></i> Logout</NavDropdown.Item>
                    </NavDropdown>
                </ul>
            </div>
        </nav>
    )
}

export default withRouter(Header);