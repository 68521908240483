const routes = {
    home: '/',
    login: "/login",
    jobs: '/jobs',
    addJob: '/jobs/add',
    feeds: '/feeds',
    rssFeeds: '/rss-feeds',
    addRssFeed: '/rss-feeds/add',
    notFound: '/404'
};

export default routes;