import { useRoutes } from 'react-router-dom';
import { lazyLoadRoutes } from '../utils/lazy-loader';
import routes from '../constants/routes';

export function AppRoutes() {
    const appRoutes = [
        {
            path: '/',
            children: [
                {
                    index: true,
                    element: lazyLoadRoutes('dashboard'),
                },
                {
                    path: routes.login,
                    element: lazyLoadRoutes('auth/login', false),
                },
                {
                    path: routes.changePassword,
                    element: lazyLoadRoutes('profile/change-password'),
                },
                {
                    path: routes.jobs,
                    element: lazyLoadRoutes('jobs/index'),
                },
                {
                    path: `${routes.addJob}/:id`,
                    element: lazyLoadRoutes('jobs/add'),
                },
                {
                    path: routes.addJob,
                    element: lazyLoadRoutes('jobs/add'),
                },               
                {
                    path: routes.feeds,
                    element: lazyLoadRoutes('feeds/index'),
                },
                {
                    path: routes.rssFeeds,
                    element: lazyLoadRoutes('rss-feeds/index'),
                },
                {
                    path: routes.addRssFeed,
                    element: lazyLoadRoutes('rss-feeds/add'),
                },
                {
                    path: `${routes.addRssFeed}/:id`,
                    element: lazyLoadRoutes('rss-feeds/add'),
                },
                {
                    path: routes.notFound,
                    element: lazyLoadRoutes('not-found', false),
                }
            ]
        },
        {
            path: '*',
            element: lazyLoadRoutes('not-found')
        }
    ];

    return useRoutes(appRoutes);
}